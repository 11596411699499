<template>
  <div class="mobile-recruit-page">
    <el-collapse v-model="activeId">
      <el-collapse-item v-for="item of positionList" :key="item.id" :title="item.positionName" :name="item.id.toString()">
        <span>技能要求</span>
        <pre>{{item.skill}}</pre>
        <span>任职要求</span>
        <pre>{{item.base}}</pre>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        activeId: '',
        positionList: []
      }
    },
    mounted() {
      this.getPositionList()
    },
    methods: {
      getPositionList() {
        axios.get('/api/position').then(data => {
          this.positionList = data.data
          this.activeId = data.data[0].id.toString()
        })
      },
    }
  }
</script>
<style lang="less">
  .mobile-recruit-page {
    padding: 8px;
  }
</style>
